import React, { Component } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import api from '../services/api';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import Button from '../components/Button';
import OverlayBlock from '../components/OverlayBlock';
import OverlayCard from '../components/OverlayCard';
import Slideshow from '../components/Slideshow';

import css from './tools-and-resources.module.scss';
import heroImage from '../images/tools-resources-hero.jpg';
import coffeChatImage from '../images/tools-resources-coffee-chat-photo.jpg';
import wayeDayImage from '../images/tools-resources-waye-day-photo.jpg';
import tshirtImage from '../images/tools-resources-t-shirt-photo.jpg';
import photo01 from '../images/tools-resources-photo-01.jpg';
import photo02 from '../images/tools-resources-photo-02.jpg';

class ResourcesLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {docs: []};
  }

  componentDidMount() {
    const query = {
      _limit: 100,
      _sort: '-createdAt',
      publish: true
    };
    const qs = queryString.stringify(query);

    fetch(`${api.host}/resources?${qs}`)
      .then(response => response.json())
      .then((data) => {
        this.setState({docs: data});
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  render() {
    const { docs } = this.state;

    return (
      <Row className={css.abt__list}>
        {docs.map(({ id, slug, title, excerpt }, i) => 
        <Col xs="10" xs-offset="1" sm="4" sm-offset="0" className={css.col} key={i}>
          <Link to={`/community/${slug || ('Resource_' + id)}`}>
            <article>
              <h1>{title}</h1>
              <p>
                {excerpt}
              </p>
            </article>
          </Link>
        </Col>
        )}
      </Row>
    );
  }
}

class WayeDay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasWayeDay: false,
      url: null
    };
  }

  componentDidMount() {
    const query = {
      _limit: 1
    };
    const qs = queryString.stringify(query);

    fetch(`${api.host}/wayedaylinks`)
      .then(response => response.json())
      .then((data) => {
        if (!!data.length) {
          const link = data[0];

          this.setState({hasWayeDay: true, url: link.url});
        }
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  render() {
    return (
      <Pane name="waye-day" heighted className={css.wayeDay__pane}>
        <OverlayBlock 
          className={css.wayeDayPane__overlayBlock}
          action={this.state.hasWayeDay}
          action_is_link
          action_url={this.state.url}
          action_text="Attend A WAYE Day">

          A Career Day For Young Entrepreneurs
          <br />
          <br />
          WAYE Day: a day of hands-on, immersive workshops lead by industry
          experts in the field that equip young entrepreneurs with the tools and
          resources to build their own brands, launch their own businesses, and
          future proof their careers.
        </OverlayBlock>
        <div className={css.photo} style={{backgroundImage: `url(${photo01})`}}></div>
      </Pane>
    )
  }
}

const ToolsAndResources = ({ data }) => (
  <Layout name="tools-and-resources">
    <SEO title="Tools & Resources" keywords={[`WAYE`, `WAYE Talks`]} />

    <Pane name="landing" className={css.paneLanding}>
      <Hero 
        name="tools-resources"
        h1="Future Proofing Tools"
        tagline="Tools and resources to help you prepare for the future of work."
        img_src={heroImage}
      />
    </Pane>

    <Pane name="waye-day-photo" heighted className={css.wayeDay__panePhoto}>
      <OverlayCard 
        name="waye-day"
        h1="WAYE Future of Work Certificate"
        className={css.wayeDay__overlayCard}
        action={true}
        action_is_link
        action_url='/contact-us'
        action_text="Learn More">

        <span className={css.wayeDay__overlayCardCopy}>
          A two-hour workshop on how technology will impact the future of work, and how you can thrive in it. You will walk away with:
          <ul>
            <li>an in-depth understanding of how advanced technologies will impact the future of work</li>
            <li>a framework to measure automation or augmentation potential in your career path</li>
            <li>the skills necessary to future-proof your career</li>
            <li>and much more!</li>
          </ul>
          This class is geared towards students, businesses, and individuals in the workforce.
        </span>
      </OverlayCard>
      <div className={css.photo} style={{backgroundImage: `url(${photo02})`}}></div>
    </Pane>

    <Pane name="waye-custom" className={css.waye__custom}>
      <Container className={css.bodyTextGroup}>
        <Row className={css.body}>
          <Col xs="12" sm="6">
            <Row relative={true}>
              <Col xs="10" xs-offset="1" sm="5" sm-offset="1" className={css.body__textMasterClass}>
                <strong>WAYE Custom Master Class</strong>
                <p>
                  A customized workshop on how advanced technologies will impact your business or industry
                </p>
              </Col>
              <Col xs="10" xs-offset="1" sm="5" sm-offset="1" className={css.body__actionMasterClass}>
                <Button to="/contact-us" className={css.buttonGetInvolved}>Learn More</Button>
              </Col>
            </Row>
          </Col>
          <Col xs="12" sm="6">
            <Row relative={true}>
              <Col xs="10" xs-offset="1" sm="5" sm-offset="1" className={css.body__textConsulting}>
                <strong>WAYE Custom Consulting</strong>
                <p>
                  Futureproof your business
                  <br />
                  &nbsp;
                </p>
              </Col>
              <Col xs="10" xs-offset="1" sm="5" sm-offset="1" className={css.body__actionConsulting}>
                <Button to="/contact-us" className={css.buttonGetInvolved}>Learn More</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Pane>

    <WayeDay />

    <Pane name="schedule-a-coffee-chat" heighted className={css.scheduleACoffeeChatGroup}>
      <OverlayBlock
        className={css.scheduleACoffeeChatGroup__overlayBlock}
        modifier="coffee-chat"
        action={true}
        action_url="https://Wayecoffeechat.as.me"
        action_text="Schedule A Coffee Chat">
        1-on-1 business consulting and general advice sessions for young
        entrepreneurs looking to build their own start-ups or make a career
        change.
      </OverlayBlock>

      <div className={css.photo} style={{backgroundImage: `url(${coffeChatImage})`}}></div>
    </Pane>

    <Pane name="waye-approved-business-tools" className={css.wayeApprovedBusinessToolsGroup}>
      <Container>
        <Row>
          <Col sm="10" sm-offset="1" className={css.abt__heading}>
            <h1>WAYE Approved Business Tools</h1>
            <ResourcesLinks />
          </Col>
        </Row>
      </Container>
    </Pane>

    <Pane name="waye-t-shirt" heighted className={css.wayeTShirtGroup}>
      <OverlayCard 
        align-right
        className={css.overlayCard}
        h1="WAYE Futurist T’s"
        action={true}
        action_is_link
        action_url="https://waye-talks.myshopify.com/"
        action_text="Shop"
      >
        Join the right side of history
      </OverlayCard>
      <div className={css.photo} style={{backgroundImage: `url(${tshirtImage})`}}></div>
    </Pane>
  </Layout>
);

/**
 * expose
 */

export default ToolsAndResources;